import React from "react";
import { Row, Col, Divider } from "antd";
// import { UserOutlined, MailOutlined } from "@ant-design/icons";
// import IntlMessages from "../utility/intlMessages";
import styled from "styled-components";

const Contact = () => {
  return (
    <ContactPageWrapper>
      <Row className="m-top-5">
        {/* <Col span={11}>
          <Form
            name="cf"
            method="post"
            onFinish={handleSubmit}
            layout="vertical"
          >
            <Form.Item
              label={<IntlMessages id={"contact.name"} />}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id={"contact.name.error"} />,
                },
              ]}
              name="name"
            >
              <Input
                placeholder="Your name"
                prefix={<UserOutlined className="site-form-item-icon" />}
              />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id={"contact.email"} />}
              rules={[
                {
                  required: true,
                  type: `email`,
                  message: <IntlMessages id={"contact.email.error"} />,
                },
              ]}
              name="email"
            >
              <Input
                placeholder="Your email"
                prefix={<MailOutlined className="site-form-item-icon" />}
              />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id={"contact.message"} />}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id={"contact.message.error"} />,
                },
              ]}
              name="message"
            >
              <TextArea placeholder="Your message" rows={5} />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={false}
                className="green-color-80-background green-color-80-border"
              >
                {<IntlMessages id={"contact.send"} />}
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={2} className="text-align-center">
          <Divider type="vertical" style={{ height: "100%" }} />
        </Col> */}
        <Col span={2}></Col>
        <Col span={11}>
          <Row className="d-contents">
            <h2>Δήμητρα Τσόλκα</h2>
            <p>Project Coordinator</p>
            <p>Intracom Telecom</p>
            {/* <p>Phone: +30 2106875450</p> */}
            <p>email: dtso at intracom-telecom.com</p>
          </Row>

          <Divider />

          <Row className="d-contents m-top-3">
            <h2>Γιώργος Παπαστεφανάτος</h2>
            <p>Scientific Coordinator</p>
            <p>Athena Research and Innovation Center</p>
            {/* <p>Phone: +30 2106875450</p> */}
            <p>email: gpapas at athenarc.gr</p>
          </Row>
        </Col>
      </Row>
    </ContactPageWrapper>
  );
};

export default Contact;

const ContactPageWrapper = styled.div`
  margin: 30px;
`;
