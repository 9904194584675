import React from "react";
import { Card, Row, Col } from "antd";
import IntlMessages from "../utility/intlMessages";
import styled from "styled-components";

const UseCases = () => {
  return (
    <UseCasesPageWrapper>
      <Row>
        <Col span={24} className="text-align-center">
          {" "}
          <h1 className="page-title">
            <IntlMessages id={"use.cases.title"} />
          </h1>
        </Col>
      </Row>

      <Row className="m-top-3">
        <Col >
          <Card
            title={<IntlMessages id={"use.cases.card.title1"} />}
            bordered={false}
            className="white-space"
            >
            <div>{<IntlMessages id={"use.cases.card.content.1.1"} />}</div>
          </Card>
        </Col>
      </Row>
      <Row className="m-top-3">
        <Col >
          <Card
            title={<IntlMessages id={"use.cases.card.title2"} />}
            bordered={false}
            className="white-space"
            >
            <div>{<IntlMessages id={"use.cases.card.content.2.1"} />}</div>
          </Card>
        </Col>
      </Row>
    </UseCasesPageWrapper>
  );
};

export default UseCases;

const UseCasesPageWrapper = styled.div`
  margin: 30px;

  .ant-card {
    border-radius: 20px;
    overflow: hidden;
  }

  .ant-card-head {
    background: var(--green-color-80) !important;

    .ant-card-head-title {
      font-size: 20px;
      font-weight: 600;
      color: var(--white-color) !important;
    }
  }

  .ant-card-body {
    padding: 40px !important;
    color: var(--black-color);
    font-size: 16px;
  }
`;
