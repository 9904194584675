import antdEl from "antd/lib/locale-provider/el_GR";
import elMessages from "../locales/el_GR.json";

const ElLang = {
  messages: {
    ...elMessages,
  },
  antd: antdEl,
  locale: "el-GR",
};
export default ElLang;
