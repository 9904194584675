import React from "react";
import styled from "styled-components";
import { Col, Row, Card } from "antd";
import IntlMessages from "../utility/intlMessages";

const Activities = () => {
  return (
    <ActivitiesPageWrapper>
      <Row>
        <Col span={24} className="text-align-center">
          {" "}
          <h1 className="page-title">
            <IntlMessages id={"activities.title"} />
          </h1>
        </Col>
      </Row>

      <Row className="d-grid m-top-3">
        <Row className="m-auto">
          <h1 className="home-text23">
            <IntlMessages id={"activities.target.title"} />
          </h1>
        </Row>

        <Row className="just-content-center text-align-center targets">
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              title={<IntlMessages id={"activities.target1.title"} />}
              bordered={false}
              >
              <IntlMessages id={"activities.target1"} />
            </Card>
          </Col>

          <Col span={1}>
          </Col>

          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              title={<IntlMessages id={"activities.target2.title"} />}
              bordered={false}
            >
              <IntlMessages id={"activities.target2"} />
            </Card>
          </Col>

          <Col span={1}>
          </Col>

          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              title={<IntlMessages id={"activities.target3.title"} />}
              bordered={false}
            >
              <IntlMessages id={"activities.target3"} />
            </Card>
          </Col>
        </Row>
      </Row>
    </ActivitiesPageWrapper>
  );
};

export default Activities;

const ActivitiesPageWrapper = styled.div`
  margin: 30px;

  .ant-card {
    border-radius: 20px;
    overflow: hidden;
  }

  .targets .ant-card-head {
    background: var(--green-color-80) !important;

    .ant-card-head-title {
      font-size: 20px;
      font-weight: 600;
      color: var(--white-color) !important;
    }
  }

  .targets .ant-card-body {
    padding: 40px !important;
    color: var(--black-color);
    font-size: 16px;
  }
`;
