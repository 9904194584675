import React from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { changeLanguage } from "../redux/language/languageActions";
import { Col, Row, Button, Menu, Image, Dropdown, Space } from "antd";
import { GlobalOutlined } from "@ant-design/icons";
import IntlMessages from "../utility/intlMessages";
import styled from "styled-components";
import logo from "../assets/arcadia_logo.png";

const Topbar = ({ changeLanguage }) => {
  const location = useLocation().pathname.substring(1);

  const menuItems = [
    {
      label: (
        <Link to="/">
          <IntlMessages id={"topbar.home"} />
        </Link>
      ),
      key: "home",
    },
    {
      label: <IntlMessages id={"topbar.about"} />,
      key: "about",
      children: [
        {
          label: (
            <Link to="/project">
              <IntlMessages id={"topbar.project"} />
            </Link>
          ),
          key: "project",
        },
        {
          label: <IntlMessages id={"topbar.deliverables"} />,
          key: "deliverables",
          disabled: true,
        },
        {
          label: (
            <Link to="/partners">
              <IntlMessages id={"topbar.partners"} />
            </Link>
          ),
          key: "partners",
        },
        {
          label: (
            <Link to="/activities">
              <IntlMessages id={"topbar.activities"} />
            </Link>
          ),
          key: "activities",
        },
        {
          label: (
            <Link to="/material">
              <IntlMessages id={"topbar.material"} />
            </Link>
          ),
          key: "material",
        }
      ],
    },
    {
      label: <IntlMessages id={"topbar.nfvri"} />,
      key: "nfvri",
      children: [
        {
          key: "software",
          label: <IntlMessages id={"topbar.software"} />,
          disabled: true,
        },
        {
          label: (
            <Link to="/use-cases">
              <IntlMessages id={"topbar.use.cases"} />
            </Link>
          ),
          key: "use-cases",
        },
        {
          label: (
            <Link to="/open-data">
              <IntlMessages id={"topbar.use.open.data"} />
            </Link>
          ),
          key: "open-data",
        }
      ],
    },
    {
      label: (
        <Link to="/news">
          <IntlMessages id={"topbar.news"} />
        </Link>
      ),
      key: "news",
    },
    {
      label: (
        <Link to="/contact">
          <IntlMessages id={"topbar.contact"} />
        </Link>
      ),
      key: "contact",
    },
    {
      label: (
        <Link to="/terms-and-data-privacy">
          <IntlMessages id={"topbar.terms"} />
        </Link>
      ),
      key: "terms-and-data-privacy",
    },
  ];

  const languagesMenu = [
    {
      label: (
        <Button
          type="text"
          onClick={() => {
            changeLanguage("english");
            // console.log("change language en");
          }}
        > English </Button>
        ),
        key: "en",
    },
    {
      label: (
        <Button
          type="text"
          onClick={() => {
            changeLanguage("greek");
            // console.log("change language en");
          }}
        >
          Ελληνικά
        </Button>
        ),
        key: "el",
    },
  ];

  return (
    <TopbarWrapper>
      <Row className="nav-bar">
        <Col span={6} className="d-flex">
          <Link to="/" className="m-auto">
            <Image src={logo} preview={false} className="toolbar-image scaled-image"/>
          </Link>
        </Col>
        <Col span={16}>
          <Menu
            mode="horizontal"
            className="white-color-background"
            selectedKeys={
              location.split("/")[0] === ""
                ? ["home"]
                : [location.split("/")[0]]
            }
            items={menuItems}
          />
        </Col>
        <Col span={1} className="text-align-center ">
          <Dropdown menu={{items: languagesMenu}} placement="bottomRight">
            <Button type="text" onClick={(e) => e.preventDefault()}>
              <Space>
                <GlobalOutlined className="black-color" />
              </Space>
            </Button>
          </Dropdown>
        </Col>
      </Row>
    </TopbarWrapper>
  );
};

export default connect(null, { changeLanguage })(Topbar);

const TopbarWrapper = styled.div`
  width: 100%;
  display: contents;
  justify-content: space-between;
  z-index: 1000;

  .nav-bar {
    margin: 0px 3%;
  }

  .ant-row .ant-col ul li span a,
  .ant-row .ant-col ul li div span,
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    font-size: 15px;
    font-style: normal;
    font-family: inherit,
    line-height: 1.5;
    text-transform: none;
    text-decoration: none;
    color: var(--green-color-80);
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected a,
  .ant-menu-horizontal > .ant-menu-item a:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
  .ant-menu-light .ant-menu-submenu-title:hover,
  .ant-menu-submenu-open div span,
  .ant-menu-submenu-active div span {
    color: var(--white-color) !important;
  }

  .ant-menu-item-active,
  .ant-menu-item-selected,
  .ant-menu-submenu-active {
    background: var(--green-color-80) !important;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
  .ant-menu-horizontal > .ant-menu-item::after,
  .ant-menu-horizontal > .ant-menu-submenu::after {
    border-bottom: none !important;
  }

  .ant-menu-horizontal {
    justify-content: flex-end;
  }
  .toolbar-image {
    max-height: 6vh; /* Set a maximum height for the image */
  }
`;
