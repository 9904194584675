import languageConfig, { getCurrentLanguage } from "./languageConfig";
import { CHANGE_LANGUAGE } from "../types";

const initialState = {
  isActivated: false,
  language: getCurrentLanguage(languageConfig.defaultLanguage || "english"),
};

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      localStorage.setItem("language", action.language.languageId);
      return {
        ...state,
        language: action.language,
      };
    default:
      return state;
  }
};

export default languageReducer;
