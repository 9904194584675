import React, { Component } from "react";
import { Col, Row, Card } from "antd";
import styled from "styled-components";
import IntlMessages from "../utility/intlMessages";

class Terms extends Component {
  render() {
    return (
      <TermsPageWrapper>
        <Row>
          <Col span={24} className="text-align-center">
            <h1 className="page-title">
              <IntlMessages id={"terms.title"} />
            </h1>
          </Col>
        </Row>

        <Row className="m-auto-lr-5-tb width-75">
          <Card className="desc-card m-auto black-color">
            <p>
              <IntlMessages id={"terms.text1"} />
            </p>
            <p>
              <IntlMessages id={"terms.text2"} />
            </p>
          </Card>
        </Row>
      </TermsPageWrapper>
    );
  }
}

export default Terms;

const TermsPageWrapper = styled.div`
  margin: 30px;

  .desc-card {
    width: 100%;
    border-top: 5px solid var(--green-color) !important;
    font-size: 16px;
  }
`;
