import React from "react";
import { Col, Row } from "antd";
import styled from "styled-components";
import IntlMessages from "../utility/intlMessages";

import poster_first from "../assets/ARCADIA_Poster_first.png";
import poster_last from "../assets/ARCADIA_Poster_last.png";

const Material = () => {
  return (
    <MaterialPageWrapper>
      <Row>
        <Col span={24} className="text-align-center">
        {" "}
          <h1 className="page-title">
              <IntlMessages id={"material.title.poster"} />
          </h1>
        </Col>
      </Row>

      <Row className="m-top-2">
        <Col span={24} className="text-align-center d-grid">
          <img src={poster_last} alt="poster" width="50%" className="m-auto"/>
          <span> <IntlMessages id={"material.caption.last"} /></span>
        </Col>
      </Row>

      <Row className="m-top-5">
        <Col span={24} className="text-align-center d-grid">
          <img src={poster_first} alt="poster" width="50%" className="m-auto"/>
          <span> <IntlMessages id={"material.caption.first"} /></span>
        </Col>
      </Row>


    </MaterialPageWrapper>
  );
};

export default Material;

const MaterialPageWrapper = styled.div`
    margin: 30px;
`;