import React from 'react';
import styled from "styled-components";
import { Row, Col, Card, Image, Space } from "antd";
import IntlMessages from "../utility/intlMessages";

import github from "../assets/github.png";
import zenodo from "../assets/zenodo.png";
import full_exp_sep from "../assets/full_exp_sep.png";

const OpenData = () => {
  return (
    <OpenDataWrapper>
       <Row>
        <Col span={24} className="text-align-center">
          {" "}
          <h1 className="page-title">
            <IntlMessages id={"open.data.title"} />
          </h1>
        </Col>
      </Row>

      <Row className="m-top-3 implementation">
        <Card
          title={<IntlMessages id={"open.data.intro.title"} />}
          bordered={false}
          style={{ width: 1200 }}
          className="m-auto"
        >
          <div className="text-align-center">
            <span>
              <IntlMessages id={"open.data.intro.content"} />
            </span>
          </div>  
        </Card>
      </Row>

      {/* <Row className="m-top-3">
        <Col span={24} className="text-align-center">
          <h2>
            <IntlMessages id={"open.data.link"} />
          </h2>
        </Col>
      </Row> */}

      <Row className="m-top-5">
        <Col xs={24} sm={24} md={4} lg={4} xl={4}> </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} className="text-align-center">
          <Space>
            <Image src={github} preview={false} height={60} width={60}/>
            <a href="https://github.com/athenarc/arcadia-project/tree/master/resource-demand-open-data"
            target="_blank" rel="noopener noreferrer">
              ARCADIA GitHub
            </a>
          </Space>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}  className="text-align-center">
        <Space>
            <Image src={zenodo} preview={false} height={60} width={140}/>
            <a href="https://zenodo.org/records/10245448"
            target="_blank" rel="noopener noreferrer">
              ARCADIA Zenodo
            </a>
          </Space>
        </Col>
      </Row>

      <Row className="m-top-5 text-align-center just-content-center">
        <div>
        <IntlMessages id={"open.data.experiments"} />
          <br /> <br />
          <Image src={full_exp_sep} preview={false} className="scaled-image" />
          </div>
      </Row>
      
    </OpenDataWrapper>
  );
};

export default OpenData;

const OpenDataWrapper = styled.div`
  margin: 30px;

  .ant-card {
    border-radius: 20px;
    overflow: hidden;
  }

  .implementation .ant-card-head {
    background: var(--green-color-80) !important;

    .ant-card-head-title {
      font-size: 30px;
      font-weight: bolder;
      color: var(--white-color) !important;
    }
  }

  .implementation .ant-card-body {
    padding: 40px !important;
    color: var(--black-color);
    font-size: 18px;
  }
`;