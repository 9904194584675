import React from "react";
import { Row, Col, Card, Image } from "antd";
import energySavingImg from "../assets/Energy_saving.png";
// import rect1 from "../assets/rectangles1.png";
// import rect2 from "../assets/rectangles2.png";
import styled from "styled-components";
import IntlMessages from "../utility/intlMessages";

const Home = () => {
  return (
    <HomePageWrapper>
      <Row className="green-color-80-background" style={{ height: 500 }}>
        <Col span={3}></Col>
        <Col span={8} className="left-banner-home m-auto">
          <Row>
            <h1 className="orange-color" style={{ margin: 0 }}>
              <IntlMessages id={"home.card1.title"} />
            </h1>
            <span className="subtitle" style={{ marginBottom: 25 }}>
              <IntlMessages id={"home.card1.subtitle"} />
            </span>
          </Row>
          <Row>
            <span className="white-color">
              <IntlMessages id={"home.card1.text"} />
            </span>
          </Row>
        </Col>
        <Col span={2}></Col>
        <Col span={8} className="image-container m-auto">
          <Image
            src={energySavingImg}
            className="main-home-img scaled-image"
            alt="green city"
          ></Image>
          {/* <Image src={rect1} className="rect1-home-img" alt="arrows"></Image> */}
          {/* <Image src={rect2} className="rect2-home-img scaled-image" alt="arrows"></Image> */}
        </Col>
        <Col span={3}></Col>
      </Row>

      <Row className="m-auto-lr-10-tb width-75">
        <Card className="desc-card m-auto black-color">
          <p>
            <IntlMessages id={"home.card2.text1"} />
          </p>
          <p>
            <IntlMessages id={"home.card2.text2"} />
          </p>
        </Card>
      </Row>
    </HomePageWrapper>
  );
};

export default Home;

const HomePageWrapper = styled.div`
  .subtitle {
    color: var(--grey-color);
    font-size: 14px;
    font-style: italic;
  }

  .image-container {
    position: relative;
  }

  .main-home-img {
    max-width: 550px;
    border-radius: 48px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 1;
  }

  .desc-card {
    width: 100%;
    border-top: 5px solid var(--green-color) !important;
    font-size: 16px;
  }
`;
