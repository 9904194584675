import React from "react";
import { Row, Col, Card, Image } from "antd";
import styled from "styled-components";
import greenCity from "../assets/green_city.jpeg";
import greenCity2 from "../assets/green_city2.jpeg";
import architectureImg from "../assets/architecture.png";
import IntlMessages from "../utility/intlMessages";

const Project = () => {
  return (
    <ProjectPageWrapper>
      <Row>
        <Col span={24} className="text-align-center">
          {" "}
          <h1 className="page-title">
            <IntlMessages id={"project.title"} />
          </h1>
        </Col>
      </Row>

      <Row className="m-top-3">
        <Col span={12} className="align-self-center text-align-center">
          <Image src={greenCity2} preview={false} />
        </Col>
        <Col span={12} className="text-align-center">
          <IntlMessages id={"project.scope1"} />
        </Col>
      </Row>

      <Row className="m-top-5">
        <Col span={12} className="text-align-center">
          <IntlMessages id={"project.scope2"} />
        </Col>
        <Col span={12} className="align-self-center text-align-center">
          <Image src={greenCity} preview={false} />
        </Col>
      </Row>

      <Row className="m-top-5 implementation">
        <Card
          title={<IntlMessages id={"project.implementation.title"} />}
          bordered={false}
          style={{ width: 1200 }}
          className="m-auto"
        >
          <div className="text-align-center">
            <h3>
              <IntlMessages id={"project.implementation.subtitle"} />
            </h3>
            <br />
            <span>
              <IntlMessages id={"project.implementation.text1"} />
            </span>
            <br /> <br />
            <span>
              <IntlMessages id={"project.implementation.text2"} />
            </span>
            <br /> <br />
            <Image src={architectureImg} className="scaled-image" />
          </div>
        </Card>
      </Row>
    </ProjectPageWrapper>
  );
};

export default Project;

const ProjectPageWrapper = styled.div`
  margin: 30px;

  .ant-card {
    border-radius: 20px;
    overflow: hidden;
  }

  .implementation .ant-card-head {
    background: var(--green-color-80) !important;

    .ant-card-head-title {
      font-size: 30px;
      font-weight: bolder;
      color: var(--white-color) !important;
    }
  }

  .implementation .ant-card-body {
    padding: 40px !important;
    color: var(--black-color);
    font-size: 18px;
  }
`;
