import React from "react";
import { Col, Row, Image } from "antd";
import styled from "styled-components";
import icomLogo from "../assets/icomLogo.png";
import athenaLogo from "../assets/athenaLogo.jpeg";
import IntlMessages from "../utility/intlMessages";

const Partners = () => {
  return (
    <PartnersPageWrapper>
      <Row className="text-align-center">
        <Col span={2}></Col>
        <Col span={10}>
          <Row>
            <h2 className="m-auto">
              <IntlMessages id={"partners.athena"} />
            </h2>
          </Row>
          <Row className="m-top-5">
            <span>
              <IntlMessages id={"partners.athena.text"} />
            </span>
          </Row>
        </Col>
        <Col span={10} className="d-flex partners-img">
          <Image src={athenaLogo} />
        </Col>
        <Col span={2}></Col>
      </Row>

      <Row className="m-top-5 text-align-center">
        <Col span={2}></Col>
        <Col span={10} className="d-flex partners-img">
          <Image src={icomLogo} />
        </Col>
        <Col span={10}>
          <Row>
            <h2 className="m-auto">
              <IntlMessages id={"partners.intracom"} />
            </h2>
          </Row>
          <Row className="m-top-5">
            <span>
              <IntlMessages id={"partners.intracom.text"} />
            </span>
          </Row>
        </Col>
        <Col span={2}></Col>
      </Row>
    </PartnersPageWrapper>
  );
};

export default Partners;

const PartnersPageWrapper = styled.div`
  margin: 30px;

  .title {
    padding: 0px 0px 6px 0px;
    font-weight: bold;
    color: #050303;
    line-height: 46px;
    border-bottom: #28ae00 solid 1px;
    width: 410px;
    margin: 30px auto;
  }

  .partners-img .ant-image {
    margin: auto !important;
  }
`;
